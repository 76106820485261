<template lang="html">
  <div class="row">
    <div class="col-sm-12">
      <vue-tabs>
        <v-tab title="Thông tin chung">
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="card">
                <div class="card-body form-card">

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Tên cuộc đua</label>
                    </div>
                    <div class="col-10">
                      <el-input
                        placeholder="Tên cuộc đua"
                        v-model="form.vi_title"
                        :class="errors.has('vi_title')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="vi_title"
                        data-vv-as="Tên cuộc đua"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('vi_title')">{{ errors.first('vi_title') }}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Tên cuộc đua (en)</label>
                    </div>
                    <div class="col-10">
                      <el-input
                        placeholder="Tên cuộc đua (en)"
                        v-model="form.en_title"
                        :class="errors.has('en_title')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="en_title"
                        data-vv-as="Tên cuộc đua (en)"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('en_title')">{{ errors.first('en_title') }}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label>SKU</label>
                    </div>
                    <div class="col-10">
                      <el-input placeholder="SKU" v-model="form.sku"></el-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Đường dẫn</label>
                    </div>
                    <div class="col-10">
                      <el-input
                        placeholder="duong-dan"
                        v-model="form.slug"
                        :class="errors.has('slug')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="slug"
                        data-vv-as="Slug"
                      >
                        <template slot="prepend">https://irace.vn/races/</template>
                      </el-input>
                      <span class="text-danger" v-if="errors.has('slug')">{{ errors.first('slug') }}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Địa điểm</label>
                    </div>
                    <div class="col-10">
                      <el-input
                        placeholder="Địa điểm"
                        v-model="form.vi_address"
                        :class="errors.has('vi_address')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="vi_address"
                        data-vv-as="Địa điểm"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('vi_address')">{{ errors.first('vi_address') }}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Địa điểm (en)</label>
                    </div>
                    <div class="col-10">
                      <el-input
                        placeholder="Địa điểm (en)"
                        v-model="form.en_address"
                        :class="errors.has('en_address')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="en_address"
                        data-vv-as="Địa điểm (en)"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('en_address')">{{ errors.first('en_address') }}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Kiểu</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="raceTypes"
                        placeholder="Chọn kiểu..."
                        v-model="form.type_id"
                        :class="errors.has('type_id')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="type_id"
                        data-vv-as="Kiểu"
                      >
                      </my-select>
                      <span class="text-danger" v-if="errors.has('type_id')">{{ errors.first('type_id') }}</span>
                    </div>
                  </div>

                  <div class="row" v-if="form.type_id == 11">
                    <div class="col-2">
                      <label>Bước chân/km</label>
                    </div>
                    <div class="col-10">
                      <el-input type="number" placeholder="Bước chân/km" v-model="form.step_to_km"></el-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Thể loại</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="raceCategories"
                        placeholder="Chọn thể loại..."
                        v-model="form.category_id"
                        :class="errors.has('category_id')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="category_id"
                        data-vv-as="Thể loại"
                      >
                      </my-select>
                      <span class="text-danger" v-if="errors.has('category_id')">{{ errors.first('category_id') }}</span>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.category_id == 4">
                    <div class="col-2">
                      <label class="required">Số ngày</label>
                    </div>
                    <div class="col-10">
                      <el-input type="number" placeholder="Số ngày" v-model="form.days"></el-input>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.category_id == 4">
                    <div class="col-2">
                      <label>Race trước</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="races"
                        placeholder="Chọn race..."
                        v-model="form.required_completed_race">
                      </my-select>
                      <i style="font-size: 12px; color: red;">* Sau khi hoàn thành race này thì mới đăng ký được</i>
                    </div>
                  </div>

                  <div class="row" v-if="form.category_id == 3">
                    <div class="col-2">
                      <label>Link đối tác</label>
                    </div>
                    <div class="col-10">
                      <el-input placeholder="Link đối tác" v-model="form.partner_link"></el-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label>Chủ đề</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="raceThemes"
                        placeholder="Chọn nhiều chủ đề..."
                        v-model="form.themes"
                      >
                      </my-select>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-2">
                      <label class="required">Đăng ký tối đa</label>
                    </div>
                    <div class="col-10">
                      <el-input
                        type="number"
                        placeholder="Đăng ký tối đa"
                        v-model="form.limit"
                        :class="errors.has('price')?'border-danger':''"
                        v-validate="'required|min_value:1'"
                        data-vv-name="limit"
                        data-vv-as="Đăng ký tối đa"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('limit')">{{ errors.first('limit') }}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
              <div class="card">
                <div class="card-body form-card">

                  <div class="form-group">
                    <label>Thời gian kết thúc khắc tên</label>
                    <el-date-picker
                      class="full-width"
                      v-model="form.engraved_at"
                      value-format="yyyy/MM/dd H:m:s"
                      type="datetime"
                      placeholder="Chọn ngày giờ"
                    >
                    </el-date-picker>
                  </div>

                  <div class="form-group" v-if="form.category_id != 4">
                    <label class="required">Thời gian mở đăng ký</label>
                    <el-date-picker
                      class="full-width"
                      v-model="form.start_register_at"
                      value-format="yyyy/MM/dd H:m:s"
                      type="datetime"
                      placeholder="Chọn ngày giờ"
                      :class="errors.has('start_register_at')?'border-danger':''"
                      v-validate="'required'"
                      data-vv-name="start_register_at"
                      data-vv-as="Thời gian mở đăng ký"
                    >
                    </el-date-picker>
                    <span class="text-danger" v-if="errors.has('start_register_at')">{{ errors.first('start_register_at') }}</span>
                  </div>

                  <div class="form-group" v-if="form.category_id != 4">
                    <label class="required">Thời gian đóng đăng ký</label>
                    <el-date-picker
                      class="full-width"
                      v-model="form.register_at"
                      value-format="yyyy/MM/dd H:m:s"
                      type="datetime"
                      placeholder="Chọn ngày giờ"
                      :class="errors.has('register_at')?'border-danger':''"
                      v-validate="'required'"
                      data-vv-name="register_at"
                      data-vv-as="Thời gian kết thúc đăng ký"
                    >
                    </el-date-picker>
                    <span class="text-danger" v-if="errors.has('register_at')">{{ errors.first('register_at') }}</span>
                  </div>

                  <div class="form-group" v-if="form.category_id != 4">
                    <label class="required">Thời gian bắt đầu ghi nhận kết quả</label>
                    <el-date-picker
                      class="full-width"
                      v-model="form.start_at"
                      value-format="yyyy/MM/dd H:m:s"
                      type="datetime"
                      placeholder="Chọn ngày giờ"
                      :class="errors.has('start_at')?'border-danger':''"
                      v-validate="'required'"
                      data-vv-name="start_at"
                      data-vv-as="Thời gian bắt đầu ghi nhận kết quả"
                    >
                    </el-date-picker>
                    <span class="text-danger" v-if="errors.has('start_at')">{{ errors.first('start_at') }}</span>
                  </div>

                  <div class="form-group" v-else>
                    <label>Thời gian bắt đầu ghi nhận kết quả</label>
                    <el-date-picker
                      class="full-width"
                      v-model="form.start_at"
                      value-format="yyyy/MM/dd H:m:s"
                      type="datetime"
                      placeholder="Chọn ngày giờ"
                    >
                    </el-date-picker>
                  </div>

                  <div class="form-group" v-if="form.category_id != 4">
                    <label class="required">Thời gian kết thúc ghi nhận kết quả</label>
                    <el-date-picker
                      class="full-width"
                      v-model="form.end_at"
                      value-format="yyyy/MM/dd H:m:s"
                      type="datetime"
                      placeholder="Chọn ngày giờ"
                      :class="errors.has('end_at')?'border-danger':''"
                      v-validate="'required|earlier'"
                      data-vv-name="end_at"
                      data-vv-as="Thời gian kết thúc ghi nhận kết quả"
                    >
                    </el-date-picker>
                    <span class="text-danger" v-if="errors.has('end_at')">{{ errors.first('end_at') }}</span>
                  </div>

                  <div class="form-group">
                    <label>Mã tham gia</label>
                    <el-input placeholder="Mã tham gia" v-model="form.join_code"></el-input>
                  </div>

                  <div class="form-group">
                    <label>Nổi bật</label>
                    <el-switch
                      class="pull-right"
                      v-model="form.hot"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </div>

                  <div class="form-group">
                    <label>Private</label>
                    <el-switch
                      class="pull-right"
                      v-model="form.unlisted"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Cự ly">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">

                  <div class="form-group row">
                    <div class="col-2">
                      <label>Không chọn cự ly</label>
                    </div>
                    <div class="col-10">
                      <el-switch
                        v-model="form.is_without_catalog"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.is_without_catalog == 0">
                    <div class="col-2">
                      <label class="required">Cự ly</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="raceCatalogs"
                        placeholder="Chọn nhiều cự ly..."
                        v-model="form.catalogs"
                        :class="errors.has('catalogs')?'border-danger':''"
                        v-validate.disable="'required'"
                        data-vv-name="catalogs"
                        data-vv-as="Cự ly"
                      >
                      </my-select>
                      <span class="text-danger" v-if="errors.has('catalogs')">{{ errors.first('catalogs') }}</span>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.catalogs && form.catalogs.length">
                    <div class="col-2">
                      <label>Giới hạn suất đăng ký</label>
                    </div>
                    <div class="col-10">
                      <el-switch
                        v-model="form.is_limit_slot_catalog"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>


                  <div v-if="list_catalogs && list_catalogs.length" v-for="(item, index) in list_catalogs" :key="index" class="form-group row">
                    <div class="col-2">
                      <label>{{ item.title }}</label>
                    </div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-6" v-if="form.is_limit_slot_catalog">
                          <el-input type="number" placeholder="Suất đăng ký" v-model="item.limit">
                            <template slot="append">suất</template>
                          </el-input>
                        </div>
                        <div class="col-6">
                          <el-input type="number" placeholder="Cut off time" v-model="item.cut_off_time">
                            <template slot="append">phút</template>
                          </el-input>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Combo">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">

                  <div class="form-group row">
                    <div class="col-2">
                      <label>Combo</label>
                    </div>
                    <div class="col-10">
                      <el-switch
                        v-model="form.is_combo"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.is_combo">
                    <div class="col-2">
                      <label>Bắt buộc mua tất cả race</label>
                    </div>
                    <div class="col-10">
                      <el-switch
                        v-model="form.is_required_buy_all_race_comobo"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.is_combo">
                    <div class="col-2">
                      <label>Chọn race</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="races"
                        :multiple="true"
                        placeholder="Chọn race..."
                        v-model="form.race_ids">
                      </my-select>
                    </div>
                  </div>

                  <div class="form-group row" v-if="form.is_combo && form.race_ids && form.race_ids.length > 1">
                    <div class="col-2">
                      <label>Giảm giá</label>
                    </div>
                    <div class="col-10">
                      <div v-for="(item, index) of form.race_ids">
                        <div class="form-group row" v-if="index < form.race_ids.length - 1">
                          <div class="col-4">Giảm giá khi mua {{ index + 2 }} race (%):</div>
                          <div class="col-8">
                            <el-input placeholder="5%" type="number" v-model="combo_discounts[index + 2]"></el-input>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Giá">
          <div class="card">
            <div class="card-body form-card">

              <div class="form-group row">
                <div class="col-2">
                  <label>Miễn phí vận chuyển</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_free_ship"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row" v-if="form.is_free_ship == 0">
                <div class="col-2">
                  <label class="required">Phí vận chuyển</label>
                </div>
                <div class="col-10">
                  <el-input
                    placeholder="Phí vận chuyển"
                    v-model="form.shipping_fee"
                    type="number"
                    :class="errors.has('shipping_fee')?'border-danger':''"
                    v-validate="'required|min_value:1'"
                    data-vv-name="shipping_fee"
                    data-vv-as="Phí vận chuyển"
                  >
                  </el-input>
                  <span class="text-danger" v-if="errors.has('shipping_fee')">{{ errors.first('shipping_fee') }}</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Miễn phí</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_free"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div v-if="form.is_free == 0" class="form-group row">
                <div class="col-2">
                  <label>Kiểu giá</label>
                </div>
                <div class="col-10">
                  <el-radio-group v-model="prices.type">
                    <el-radio-button label="one">Một giá</el-radio-button>
                    <el-radio-button label="many">Nhiều giá</el-radio-button>
                    <el-radio-button label="catalog">Giá theo cự ly</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="form.is_free == 0 && prices.type == 'one'">
            <div class="card-body form-card">

              <div class="form-group">
                <label class="catalog-title">Một giá</label>
              </div>

              <div class="form-group row">
                <div class="col-6">
                  <el-row :gutter="20">
                    <el-col :span="6">Giá bán</el-col>
                    <el-col :span="18">
                      <el-input placeholder="Giá bán" v-model="prices.one.standard" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
                <div class="col-6">
                  <el-row :gutter="20">
                    <el-col :span="6">Giá so sánh</el-col>
                    <el-col :span="18">
                      <el-input placeholder="Giá so sánh" v-model="prices.one.compare" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="form.is_free == 0 && prices.type == 'many'">
            <div class="card-body form-card">
              <div class="form-group">
                <label class="catalog-title">Nhiều giá</label>
              </div>
              <div class="form-group row">
                <div class="col-2">Super early bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-input placeholder="Giá Super early bird" v-model="prices.many.super_early_bird" type="number"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.start_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.end_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.many.inventory_super_early_bird" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Early bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-input placeholder="Giá Early bird" v-model="prices.many.early_bird" type="number"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.start_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.end_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.many.inventory_early_bird" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Regular</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-input placeholder="Giá Regular" v-model="prices.many.regular" type="number"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.start_at_regular"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.end_at_regular"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.many.inventory_regular" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Late</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-input placeholder="Giá Late" v-model="prices.many.late" type="number"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.start_at_late"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.end_at_late"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.many.inventory_late" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-show="form.is_free == 0 && prices.type == 'catalog'">
            <div class="card-body form-card">
              <div class="form-group">
                <b class="catalog-title">Giá theo cự ly</b>
              </div>
              <div class="form-group row">
                <div class="col-2">Chọn cự ly</div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="raceCatalogs"
                    placeholder="Chọn nhiều cự ly..."
                    v-model="form.catalogs"
                  >
                  </my-select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Super Early Bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.start_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.end_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.catalog.inventory_super_early_bird" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Early Bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.start_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.end_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.catalog.inventory_early_bird" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Regular</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.start_at_regular"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.end_at_regular"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.catalog.inventory_regular" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">Late</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.start_at_late"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.end_at_late"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-input placeholder="Số suất đăng ký" v-model="prices.catalog.inventory_late" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <hr>
              <div v-if="catalogSelectedPrice && catalogSelectedPrice.length" class="list-price-catalog">
                <div v-for="item of catalogSelectedPrice" class="form-group row">
                  <div class="col-2">{{ item.title }}</div>
                  <div class="col-10">
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-input placeholder="Super Early Bird" v-model="item.super_early_bird" type="number"></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input placeholder="Early Bird" v-model="item.early_bird" type="number"></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input placeholder="Regular" v-model="item.regular" type="number"></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input placeholder="Late" v-model="item.late" type="number"></el-input>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </v-tab>

        <v-tab title="Hình ảnh">
          <div class="card">
            <div class="card-body form-card">

              <div class="form-group row">
                <div class="col-2">
                  <label>Logo</label>
                </div>
                <div class="col-10">
                  <my-image v-model="form.logo"></my-image>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Ảnh cover</label>
                </div>
                <div class="col-10">
                  <my-image v-model="form.image"></my-image>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Huy chương (Medal)</label>
                  <br />
                  <a :href="window.SERVER_BASE_URL + '/images/medal-01.png'" target="_blank">Tải template 01</a>
                  <br />
                  <a :href="window.SERVER_BASE_URL + '/images/medal-02.png'" target="_blank">Tải template 02</a>
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="col-6">
                      <my-image v-model="form.medal"></my-image>
                    </div>
                    <div class="col-6">
                      <canvas style="max-width:300px" ref="canvas-medal-deactive"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Số BIB</label>
                  <br />
                  <a :href="window.SERVER_BASE_URL + '/images/bib-placeholder.jpg'" target="_blank">Tải template</a>
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="col-6">
                      <my-image v-model="form.bib"></my-image>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5 mid-text">Số BIB</div>
                        <div class="col-7">
                          <el-color-picker v-model="color_options.bib_number"></el-color-picker>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5 mid-text">Tên + cự ly</div>
                        <div class="col-7">
                          <el-color-picker v-model="color_options.bib_text"></el-color-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Giấy chứng nhận</label>
                  <br />
                  <a :href="window.SERVER_BASE_URL + '/images/cert-placeholder.jpg'" target="_blank">Tải template</a>
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="col-6">
                      <my-image v-model="form.certificate"></my-image>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5 mid-text">Tên người dùng</div>
                        <div class="col-7">
                          <el-color-picker v-model="color_options.cert_name"></el-color-picker>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5 mid-text">Kết quả</div>
                        <div class="col-7">
                          <el-color-picker v-model="color_options.cert_result"></el-color-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Về cuộc đua">
          <div class="card">
            <div class="card-body form-card">
              <div class="form-group row">
                <div class="col-2">
                  <label>Mô tả ngắn</label>
                </div>
                <div class="col-10">
                  <el-tabs v-model="active_tab_description" type="border-card">
                    <el-tab-pane label="Tiếng việt" name="first">
                      <el-input type="textarea" v-model="form.description_vi" placeholder="Mô tả ngắn" :rows="3"></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="Tiếng Anh" name="second">
                      <el-input type="textarea" v-model="form.description_en" placeholder="Mô tả ngắn" :rows="3"></el-input>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body form-card">
              <div class="form-group row">
                <div class="col-2">
                  <label>Giới thiệu</label>
                </div>
                <div class="col-10">
                  <el-tabs v-model="active_tab_introduce" type="border-card">
                    <el-tab-pane label="Tiếng việt" name="first">
                      <my-editor
                        v-model="form.vi_introduce"
                        apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                      </my-editor>
                    </el-tab-pane>
                    <el-tab-pane label="Tiếng Anh" name="second">
                      <my-editor
                        v-model="form.en_introduce"
                        apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                      </my-editor>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body form-card">
              <div class="form-group row">
                <div class="col-2">
                  <label>Giải thưởng</label>
                </div>
                <div class="col-10">
                  <el-tabs v-model="active_tab_award" type="border-card">
                    <el-tab-pane label="Tiếng việt" name="first">
                      <my-editor
                        v-model="form.vi_award"
                        apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                      </my-editor>
                    </el-tab-pane>
                    <el-tab-pane label="Tiếng Anh" name="second">
                      <my-editor
                        v-model="form.en_award"
                        apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                      </my-editor>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="SEO">
          <div class="card">
            <div class="card-body form-card">
              <div class="form-group row">
                <div class="col-2">
                  <label>Tiêu đề</label>
                </div>
                <div class="col-10">
                  <el-tabs v-model="active_tab_seo_title" type="border-card">
                    <el-tab-pane label="Tiếng việt" name="first">
                      <el-input v-model="form.vi_title_seo" placeholder="Tiêu đề" :rows="3"></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="Tiếng Anh" name="second">
                      <el-input v-model="form.en_title_seo" placeholder="Tiêu đề" :rows="3"></el-input>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body form-card">
              <div class="form-group row">
                <div class="col-2">
                  <label>Mô tả</label>
                </div>
                <div class="col-10">
                  <el-tabs v-model="active_tab_seo_description" type="border-card">
                    <el-tab-pane label="Tiếng việt" name="first">
                      <el-input type="textarea" v-model="form.vi_description_seo" placeholder="Mô tả" :rows="3"></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="Tiếng Anh" name="second">
                      <el-input type="textarea" v-model="form.en_description_seo" placeholder="Mô tả" :rows="3"></el-input>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>

        </v-tab>

        <v-tab title="Quỹ từ thiện">
          <div class="card">
            <div class="card-body form-card">
              <div class="form-group row">
                <div class="col-2">
                  <label>Quỹ từ thiện</label>
                </div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="charities"
                    placeholder="Chọn quỹ từ thiện..."
                    v-model="form.charity_id">
                  </my-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Số tiền đóng góp</label>
                </div>
                <div class="col-10">
                  <el-input type="number" v-model="form.charity_money" placeholder="0"></el-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Tổng tiền đóng góp</label>
                </div>
                <div class="col-10">
                  <el-input type="number" v-model="form.total_donate" placeholder="0"></el-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>KM/VND</label>
                </div>
                <div class="col-10">
                  <el-input type="number" v-model="form.km_to_vnd" placeholder="0"></el-input>
                </div>
              </div>

            </div>
          </div>
        </v-tab>

        <v-tab title="Quy định">
          <div class="card">
            <div class="card-body form-card">

              <div class="form-group row">
                <div class="col-2">
                  <label>Hoàn thành trong một lần chạy</label>
                </div>
                <div class="col-10">
                  <el-switch v-model="form.is_one_lap" :active-value="1" :inactive-value="0"></el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Cut off time</label>
                </div>
                <div class="col-4">
                  <el-input type="number" placeholder="Nhập số phút" v-model="form.cut_off_time">
                    <template slot="append">phút</template>
                  </el-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Quy định tính kết quả</label>
                </div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="raceRules"
                    :multiple="true"
                    placeholder="Quy định tính kết quả"
                    v-model="list_race_rules">
                  </my-select>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row" v-for="(item, index) in list_race_rules" :key="index">
                  <div class="col-2">{{ hash_rules[item] }}</div>
                  <div class="col-10">
                    <el-switch
                        v-if="list_rules_checkbox.includes(item)"
                        v-model="list_active_rules[item]"
                        :active-value="1"
                        :inactive-value="0"
                        disabled>
                      </el-switch>
                    <el-input v-else-if="item === 'TIME_SLOT'" type="textarea" :rows="3" v-model="list_active_rules[item]" placeholder="08:00-13:00 (Mỗi khoảng thời gian 1 dòng)"></el-input>
                    <el-input v-else-if="item === 'DATE_SLOT'" type="textarea" :rows="3" v-model="list_active_rules[item]" placeholder="2020-10-20 (Mỗi ngày 1 dòng)"></el-input>
                    <el-input v-else-if="item === 'MIN_DISTANCE_DATE'" type="textarea" :rows="3" v-model="list_active_rules[item]" placeholder="2020-10-20#####13 (Mỗi ngày 1 dòng)"></el-input>
                    <el-input v-else-if="item === 'DATE_HAVE_ONE_ACTIVITY_WITH_MIN_DISTANCE'" type="textarea" :rows="3" v-model="list_active_rules[item]" placeholder="2020-10-20#####13 (Mỗi ngày 1 dòng)"></el-input>
                    <el-input v-else v-model="list_active_rules[item]" :placeholder="hash_rules[item]" type="number"></el-input>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body form-card">

              <div class="form-group row">
                <div class="col-2">
                  <label>Quy định (select)</label>
                </div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="rules"
                    :multiple="true"
                    placeholder="Chọn quy định"
                    v-model="form.rules">
                  </my-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Quy định (text)</label>
                </div>
                <div class="col-10">
                  <el-tabs v-model="active_tab_rule" type="border-card">
                    <el-tab-pane label="Tiếng việt" name="first">
                      <my-editor
                        v-model="form.rule_vi"
                        apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                      </my-editor>
                    </el-tab-pane>
                    <el-tab-pane label="Tiếng Anh" name="second">
                      <my-editor
                        v-model="form.rule_en"
                        apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                      </my-editor>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>

        </v-tab>

        <v-tab title="Banner quảng cáo">
          <div class="card">
            <div class="card-body form-card">
              <div v-if="list_banner_ads && list_banner_ads.length" class="form-group">
                <div v-for="(item, index) in list_banner_ads" :key="index" class="row form-group item-banner-ads">
                  <div class="col-4">
                    <my-image v-model="item.image"></my-image>
                  </div>
                  <div class="col-8 right">
                    <el-input v-model="item.title" class="form-group" placeholder="Tiêu đề"></el-input>
                    <el-input v-model="item.link" placeholder="Link"></el-input>
                  </div>
                </div>
              </div>
              <div class="btn-add-banner-adds">
                <el-button type="primary" @click="addBannerAds">Thêm banner</el-button>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Facebook">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">

                  <div class="form-group row">
                    <div class="col-2">
                      <label>Facebook</label>
                    </div>
                    <div class="col-10">
                      <el-switch
                        v-model="form.fb_post"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>

                  <div v-if="form.fb_post">

                    <div class="form-group row">
                      <div class="col-2">
                        <label>Chọn loại</label>
                      </div>
                      <div class="col-10">
                        <el-select v-model="fb_info.fb_type" placeholder="Chọn loại" clearable class="full-width" multiple>
                          <el-option label="Photo" value="photo"></el-option>
                          <el-option label="Video" value="video"></el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-2">
                        <label>Hashtag</label>
                      </div>
                      <div class="col-10">
                        <el-tag v-for="(tag, index) in fb_info.hashtag" :key="index" closable :disable-transitions="false" type="info" @close="removeHashtag(tag)">{{ tag }}</el-tag>
                        <el-input
                          class="input-new-tag"
                          v-if="input_visible_hashtag"
                          v-model="input_new_hashtag"
                          ref="saveInputHashtag"
                          size="mini"
                          @keyup.enter.native="handleInputConfirmHashtag"
                          @blur="handleInputConfirmHashtag"
                        >
                        </el-input>
                        <el-button v-else class="button-new-tag" size="small" @click="showInputHashtag">+ New Tag</el-button>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-2">
                        <label>Tính điểm (total)</label>
                      </div>
                      <div class="col-10">

                        <div class="form-group row">
                          <div class="col-2 pd">1 Share bằng</div>
                          <div class="col-2" style="padding-left: 0;">
                            <el-input type="number" v-model="fb_info.share_point"></el-input>
                          </div>
                          <div class="col-3 pd">điểm</div>
                        </div>

                        <div class="form-group row">
                          <div class="col-2 pd">1 Comment bằng</div>
                          <div class="col-2" style="padding-left: 0;">
                            <el-input type="number" v-model="fb_info.comment_point"></el-input>
                          </div>
                          <div class="col-3 pd">điểm</div>
                        </div>

                        <div class="form-group row">
                          <div class="col-2 pd">1 Reaction bằng</div>
                          <div class="col-2" style="padding-left: 0;">
                            <el-input type="number" v-model="fb_info.reaction_point"></el-input>
                          </div>
                          <div class="col-3 pd">điểm</div>
                        </div>

                        <div class="form-group row">
                          <div class="col-2 pd">1 View bằng</div>
                          <div class="col-2" style="padding-left: 0;">
                            <el-input type="number" v-model="fb_info.view_point"></el-input>
                          </div>
                          <div class="col-3 pd">điểm</div>
                        </div>

                        <div class="form-group row">
                          <div class="col-2 pd">Hệ số reach
                            <div><small>(Reach = total x hệ số)</small></div>
                          </div>
                          <div class="col-2" style="padding-left: 0;">
                            <el-input type="number" v-model="fb_info.reach_point"></el-input>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Tiện ích">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">

                  <div class="form-group row">
                    <div class="col-2">
                      <label>Tiện ích</label>
                    </div>
                    <div class="col-10">
                      <my-select
                        class="full-width"
                        :attribute="addons"
                        :multiple="true"
                        placeholder="Chọn tiện ích"
                        v-model="form.addons">
                      </my-select>
                    </div>
                  </div>

                  <div v-if="list_addons && list_addons.length" v-for="(item, index) in list_addons" :key="index" class="form-group row">
                    <div class="col-2">
                      <label>{{ getAddonName(item.id) }}</label>
                    </div>
                    <div class="col-10">
                      <el-switch
                        v-model="item.required"
                        :active-value="1"
                        :inactive-value="0"
                        active-text="Bắt buộc">
                      </el-switch>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Thông tin khác">
          <div class="card">
            <div class="card-body form-card">

              <div class="form-group row">
                <div class="col-2">
                  <label>Địa điểm nhận huy chương</label>
                </div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="locations"
                    :multiple="true"
                    placeholder="Chọn địa điểm"
                    v-model="form.locations">
                  </my-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Sự kiện của</label>
                </div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="objects"
                    placeholder="Chọn đối tượng"
                    v-model="form.object">
                  </my-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Gửi email nhắc users khi giải còn X ngày</label>
                </div>
                <div class="col-10">
                  <el-input placeholder="Nhập số ngày" v-model="form.remind_send_email_day">
                    <template slot="append">ngày</template>
                  </el-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Áp dụng iVip</label>
                </div>
                <div class="col-10">
                  <el-select v-model="form.apply_vip_object" placeholder="Chọn đối tượng áp dụng" clearable class="full-width">
                    <el-option label="Sự kiện" value="race"></el-option>
                    <el-option label="Tiện ích" value="addon"></el-option>
                    <el-option label="Tất cả" value="all"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Huy chương thật</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_have_medal"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Huy chương điện tử</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_have_medal_online"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Giấy chứng nhận</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_have_certificate"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Hiển thị số BIB</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_show_bib"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Áp dụng Coupon</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_apply_coupon"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Chia CLB theo bảng</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="form.is_show_group_club"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row" v-if="form.is_show_group_club">
                <div class="col-2">
                  <label>Chọn danh sách bảng</label>
                </div>
                <div class="col-10">
                  <my-select
                    class="full-width"
                    :attribute="groups"
                    :multiple="true"
                    placeholder="Chọn danh sách bảng"
                    v-model="form.group_clubs">
                  </my-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Bắt buộc nhập CMND</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="metafield.required_field.identity_card"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-2">
                  <label>Bắt buộc chọn CLB</label>
                </div>
                <div class="col-10">
                  <el-switch
                    v-model="metafield.required_field.club"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>

            </div>
          </div>
        </v-tab>

        <v-tab title="Thông tin thêm">
          <div class="card">
            <div class="card-body form-card">

              <div v-if="metafield.custom_field && metafield.custom_field.length" class="form-group">
                <div v-for="(item, index) in metafield.custom_field" :key="index" class="row form-group item-banner-ads">

                  <div class="col-5">
                    <el-input v-model="item.title" class="form-group" placeholder="Tiêu đề">
                      <template slot="prepend">Tiêu đề</template>
                    </el-input>
                  </div>
                  <div class="col-4">
                    <el-select v-model="item.input_type" placeholder="Chọn loại input" clearable class="full-width">
                      <el-option label="Input" value="input"></el-option>
                      <el-option label="Textarea" value="textarea" disabled></el-option>
                      <el-option label="Editor" value="editor" disabled></el-option>
                    </el-select>
                  </div>

                  <div class="col-2" style="padding-top: 9px;">
                    <el-switch
                      v-model="item.required"
                      :active-value="1"
                      :inactive-value="0"
                      active-text="Bắt buộc">
                    </el-switch>
                  </div>

                  <div class="col-1">
                    <el-button type="danger" icon="el-icon-delete" circle @click="removeCustomField(index)"></el-button>
                  </div>

                </div>
              </div>
              <div class="btn-add-banner-adds">
                <el-button type="primary" @click="addCustomField">Thêm</el-button>
              </div>

            </div>
          </div>
        </v-tab>

      </vue-tabs>
    </div>

    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>

    <loading :active.sync="loading"></loading>

  </div>
</template>

<script>
  import { MessageBox, Message, Input, Upload, Table, TableColumn, DatePicker, Button, Switch, CheckboxGroup, Checkbox, Dialog, Form, FormItem, ColorPicker, Row, Col, Option, Select, RadioGroup, RadioButton, Tag, Tabs, TabPane } from 'element-ui';
  import MySelect from 'src/components/UIComponents/Select'
  import MyImage from 'src/components/UIComponents/Image'
  import MyEditor from 'src/components/UIComponents/Editor'
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'
  import moment from 'moment'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      ElTag: Tag,
      ElInput: Input,
      ElForm: Form,
      ElFormItem: FormItem,
      ElButton: Button,
      ElUpload: Upload,
      ElTable: Table,
      ElTableColumn: TableColumn,
      ElSwitch: Switch,
      ElDatePicker: DatePicker,
      ElCheckboxGroup: CheckboxGroup,
      ElCheckbox: Checkbox,
      ElDialog: Dialog,
      ElColorPicker: ColorPicker,
      ElRow: Row,
      ElCol: Col,
      ElSelect: Select,
      ElOption: Option,
      ElRadioGroup: RadioGroup,
      ElRadioButton: RadioButton,
      ElTabs: Tabs,
      ElTabPane: TabPane,
      MySelect,
      VueTabs,
      VTab,
      Message,
      MyImage,
      Loading,
      MyEditor
    },

    beforeCreate() {
      this.$store.dispatch('setPageTitle', 'Tạo cuộc đua');
      this.$store.dispatch('fetchRaceTypes');
      this.$store.dispatch('fetchRaceCategories');
      this.$store.dispatch('fetchRaceCatalogs');
      this.$store.dispatch('fetchLocations');
      this.$store.dispatch('fetchCharities');
      this.$store.dispatch('fetchRules');
      this.$store.dispatch('fetchRaceRules');
      this.$store.dispatch('fetchThemes');
      this.$store.dispatch('fetchRaces');
      this.$store.dispatch('fetchGroups');
    },

    created() {
      let self = this;
      this.$validator.extend('earlier', {
        getMessage(field, val) {
          return 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu'
        },
        validate(value, field) {
          let startParts = self.form.start_at
          let momentStart = new Date(startParts).getTime();
          let momentEnd = new Date(value).getTime();
          return momentEnd > momentStart;
        }
      })
    },

    data() {
      return {
        form: {
          medal: '',
          medal_deactive: '',
          is_free: 1,
          object: 'irace',
          is_without_catalog: 0,
          is_free_ship: 1
        },
        metafield: {
          required_field: {},
          custom_field: []
        },
        prices: {
          type: '',
          one: {},
          many: {},
          catalog: {}
        },
        loading: false,
        combo_discounts: {},
        active_tab_description: 'first',
        active_tab_introduce: 'first',
        active_tab_award: 'first',
        active_tab_seo: 'first',
        active_tab_seo_title: 'first',
        active_tab_seo_description: 'first',
        active_tab_rule: 'first',
        typeForm: {
          vi_title: '',
          en_title: '',
          slug: ''
        },
        meta: [],
        catalogSelectedPrice: [],
        hash_rules: {},
        list_race_rules: [],
        list_rules_checkbox: ['MANUAL_CREATED', 'FROM_ACCEPTED_TAG', 'GPS', 'PRIVATE', 'ACTIVITY_OVERLAP', 'CONTINUOUS_ACTIVITIES'],
        list_active_rules: {},
        list_banner_ads: [],
        list_addons: [],
        list_catalogs: [],
        fb_info: {
          hashtag: []
        },
        input_visible_hashtag: false,
        input_new_hashtag: '',
        color_options: {
          bib_number: null,
          bib_text: null,
          cert_name: null,
          cert_result: null
        }
      }
    },

    mounted() {
      this.$store.dispatch('setCurrentActions', [{
        label: 'Lưu lại',
        type: 'primary',
        icon: '',
        callback: this.save
      }]);

      this.getListAddons();

    },

    computed: {
      raceRules() {
        let data = this.$store.state.raceRules;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let key in data) {
          this.hash_rules[key] = data[key];
          arr.options.push({
            value: key,
            title: data[key]
          });
        }
        return arr;
      },
      raceTypes() {
        let data = this.$store.state.raceTypes;
        let arr = {};
        arr.options = [];
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      raceCategories() {
        let dat = this.$store.state.raceCategories;
        let arr = {};
        arr.options = [];
        for (let item of dat) {
          arr.options.push({
            value: item.id,
            title: item.name
          });
        }
        return arr;
      },
      raceCatalogs() {
        let data = this.$store.state.raceCatalogs;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.searchable
          });
        }
        return arr;
      },
      raceThemes() {
        let data = this.$store.state.themes;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      addons() {
        let data = this.$store.state.addons;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      locations() {
        let data = this.$store.state.locations;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.name
          });
        }
        return arr;
      },
      groups() {
        let data = this.$store.state.groups;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.name
          });
        }
        return arr;
      },
      races() {
        let data = this.$store.state.races;
        let arr = {};
        arr.options = [{
          title: "Chọn race...",
          value: 0
        }];
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      charities() {
        let data = this.$store.state.charities;
        let arr = {};
        arr.options = [{
          title: "Chọn quỹ từ thiện...",
          value: -1
        }];
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      rules() {
        let data = this.$store.state.rules;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      objects() {
        let arr = {};
        arr.options = [{
          value: 'irace',
          title: 'iRace'
        }, {
          value: 'enterprise',
          title: 'Doanh nghiệp'
        }];
        return arr;
      }
    },

    methods: {

      getListAddons() {
        if (this.form.race_ids && this.form.race_ids.length) {
          this.$store.dispatch('fetchAddons', {
            race_ids: this.form.race_ids.join(',')
          });
        } else {
          this.$store.dispatch('fetchAddons');
        }
      },

      getAddonName(addon_id) {
        let options = this.addons.options;
        let addon = options.find(x => x.value == addon_id);
        if (addon && addon.title) return addon.title;
        return null;
      },

      removeHashtag(tag) {
        this.fb_info.hashtag.splice(this.fb_info.hashtag.indexOf(tag), 1);
      },

      showInputHashtag() {
        this.input_visible_hashtag = true;
        this.$nextTick(_ => {
          this.$refs.saveInputHashtag.$refs.input.focus();
        });
      },

      handleInputConfirmHashtag() {
        let input_new_hashtag = this.input_new_hashtag;
        if (input_new_hashtag) {
          this.fb_info.hashtag.push(input_new_hashtag);
        }
        this.input_visible_hashtag = false;
        this.input_new_hashtag = '';
      },

      addBannerAds() {
        this.list_banner_ads.push({
          title: '',
          link: '',
          image: ''
        });
      },

      addCustomField() {
        this.metafield.custom_field.push({
          required: 0,
          title: '',
          input_type: ''
        });
      },

      removeCustomField(index) {
        this.metafield.custom_field.splice(index, 1);
      },

      cancel() {
        this.$router.push({name: 'AllRace'});
      },

      validatePrice() {
        if (this.form.is_free) return 'ok';
        switch (this.prices.type) {
          case 'one':
            let one = this.prices.one;
            if (!one.standard) {
              return 'Vui lòng nhập giá bán';
            }
            break;
          case 'many':
            let many = this.prices.many;
            let check = false;

            if (many.super_early_bird > 0) {
              check = true;
              if (!many.start_at_super_early_bird || !many.end_at_super_early_bird) {
                return 'Vui lòng chọn ngày bắt đầu và kết thúc giá Super early bird';
              }
            }

            if (many.early_bird > 0) {
              check = true;
              if (!many.start_at_early_bird || !many.end_at_early_bird) {
                return 'Vui lòng chọn ngày bắt đầu và kết thúc giá Early bird';
              }
            }

            if (many.regular > 0) {
              check = true;
              if (!many.start_at_regular || !many.end_at_regular) {
                return 'Vui lòng chọn ngày bắt đầu và kết thúc giá Regular';
              }
            }

            if (many.late > 0) {
              check = true;
              if (!many.start_at_late || !many.end_at_late) {
                return 'Vui lòng chọn ngày bắt đầu và kết thúc giá Late';
              }
            }

            if (!check) {
              return 'Vui lòng nhập ít nhất 1 giá (Super early bird hoặc Early bird)';
            }
            break;
          case 'catalog':
            let catalog = this.prices.catalog;
            if (!catalog.items || !catalog.items.length) {
              return 'Vui lòng chọn ít nhất 1 cự ly';
            }
            check = true;
            for (let i = 0; i < catalog.items.length; i++) {
              if (!catalog.items[i].super_early_bird && !catalog.items[i].early_bird && !catalog.items[i].regular && !catalog.items[i].late) {
                check = false;
                break;
              }
            }
            if (!check) {
              return 'Vui lòng nhập giá bán cho từng cự ly';
            }
            break;
          default:
            return 'ok';
        }
        return 'ok';
      },

      async save() {
        let self = this;
        let form = this.form;

        let checkPrice = this.validatePrice();
        if (checkPrice != 'ok') {
          return Message({message: checkPrice, type: 'warning'});
        }

        form.prices = this.prices;
        form.combo_discounts = this.combo_discounts;
        form.list_banner_ads = this.list_banner_ads.map(x => Object.assign({}, x));
        form.list_addons = this.list_addons.map(x => Object.assign({}, x));
        form.list_catalogs = this.list_catalogs.map(x => Object.assign({}, x));
        form.fb_info = Object.assign({}, this.fb_info);
        form.color_options = Object.assign({}, this.color_options);

        if (Object.keys(this.list_active_rules).length > 0) {
          form.race_rules = Object.assign({}, this.list_active_rules);
        }

        if (form.category_id == 4) {
          if (!form.days) {
            return Message({message: "Chưa nhập số ngày", type: 'warning'});
          }
        }

        if (form.type_id == 11) {
          if (!form.step_to_km) {
            return Message({message: "Chưa nhập số bước chân/km", type: 'warning'});
          }
        }

        this.$validator.validateAll().then(async function (result) {
          if (!result) {
            return Message({message: "Vui lòng nhập đầy thủ thông tin", type: 'danger'});
          }

          self.loading = true;

          await self.$store.dispatch('storeRace', form).then(res => {
            self.storeMetafield(res.data.id);
            Message({message: 'Tạo mới thành công', type: 'success'});
            setTimeout(_ => {
              self.$router.push('/races/' + res.data.id);
            }, 1500);
          }, error => {
            Message({
              dangerouslyUseHTMLString: true,
              message: error,
              type: 'error'
            });
          });
          self.loading = false;
        })
      },

      storeMetafield(object_id) {
        for (let key in this.metafield) {
          let value = this.metafield[key];
          if (Array.isArray(value)) {
            if (!value.length) continue;
          } else {
            if (!Object.keys(value).length) continue;
          }

          let data = {
            object: 'race',
            object_id: object_id,
            key: key.toUpperCase(),
            value: value
          };
          this.$store.dispatch('storeMetafield', data);
        }
      }
    },

    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    },

    watch: {

      'form.race_ids': function(val) {
        if (!val) return false;
        this.getListAddons();
      },

      'form.addons': function(newVal) {
        this.list_addons = [];
        for (let i of this.form.addons) {
          this.list_addons.push({
            id: i,
            required: false
          });
        }
      },

      list_race_rules(newVal) {
        let temp = {};
        for (let item of newVal) {
          if (this.list_rules_checkbox.includes(item)) {
            temp[item] = 1;
          } else {
            temp[item] = undefined;
          }
        }
        this.list_active_rules = Object.assign({}, temp);
      },

      catalogSelectedPrice(newVal) {
        this.prices.catalog.items = newVal;
      },

      'form.catalogs': function(newVal) {
        this.catalogSelectedPrice = [];
        this.list_catalogs = [];

        if (this.form.catalogs && this.form.catalogs.length) {
          this.form.catalogs.forEach(id => {
            let index = this.raceCatalogs.options.findIndex(x => x.value == id);
            let title = this.raceCatalogs.options[index].title;

            this.catalogSelectedPrice.push({
              id: id,
              title: title
            });

            this.list_catalogs.push({
              id: id,
              title: title,
              limit: 999999999,
              cut_off_time: null
            });

          });
        }
      },
      'form.vi_title': function(newVal) {
        if (newVal) {
          this.form.slug = this.$util.createHandle(newVal);
        }
      },
      'form.medal': function(newVal, oldVal) {
        let self = this;
        let canvas = this.$refs['canvas-medal-deactive'];
        this.$util.canvasRender(newVal, canvas, (canvas, ctx, newVal) => {
          var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          var data = imageData.data;

          for(var i = 0; i < data.length; i += 4) {
            var brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = brightness;
            data[i + 1] = brightness;
            data[i + 2] = brightness;
          }

          ctx.putImageData(imageData, 0, 0);
          self.form.medal_deactive = canvas.toDataURL();
        });
      }
    }
  }
</script>

<style lang="scss" scoped>

  .price-title {
    padding-top: 25px;
  }

  .wrapper-prices {
    .catalog-title {
      font-size: 18px;
    }
    .col-child {
      padding: 0 5px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

</style>
